<template>
<Page id="gbook" title="Gästbok">
      <Loader v-if="entriesloading" rows="10" cols="3" loadingtext="Hämtar inlägg..."></Loader>
      <b-container v-else>
            <b-row class="my-4">
                  <b-col class="text-right">
                        <b-button @click="AddEntry()" variant="primary">Nytt inlägg</b-button>
                  </b-col>
            </b-row>
            <Entry v-for="entry in entries" :key="entry.id" :entry="entry" ></Entry>
            <Alert v-if="entries.length <= 0" variant="info" >Inga inlägg i gästboken! Bli först med att skriva!</Alert>
      </b-container>    

      <b-modal ref="AddEntryModal" title="Nytt inlägg" hide-footer no-close-on-esc no-close-on-backdrop>
            <AddEntry v-on:saved="AddEntrySaved()" v-on:close="CloseAddEntry()"></AddEntry>
      </b-modal> 
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const Entry = () => import("@/components/gbook/Entry");
const AddEntry = () => import("@/components/gbook/AddEntry");

export default {
      name: "Gbook",
      components: {           
            Alert,
            Page,
            Loader,            
            Entry,
            AddEntry
      },
      data() {
            return {};
      },
      metaInfo: {
            title: "Gästbok",
            meta: [{
                  name: "description",
                  content: "Skriv i gästboken",
            }, ],
      },
      computed: {
            entriesloading() {
                  return this.$store.getters["gbookg/entriesloading"];
            },
            entries() {
                  var items = this.$store.getters["gbookg/entries"];
                  if (!items) items = [];
                  return items;
            }            
      },
      methods: {
           AddEntry() {
                  this.$refs.AddEntryModal.show();
            },
            AddEntrySaved() {
                  this.CloseAddEntry();
                  this.GetEntries();
            },
            CloseAddEntry() {
                  this.$refs.AddEntryModal.hide();
            },
            GetEntries()
            {
                  this.$store.dispatch("gbookg/getEntries");
            }
      },
      mounted() {     
            this.GetEntries();       
      },
};
</script>
